import '../App.css';

import { Container, Button, Navbar, Nav, NavItem, NavDropdown, MenuItem, NavLink,FormControl ,Form} from 'react-bootstrap';

import $ from "jquery";
import Faq from "./Faq";
import React, { useState, Component } from 'react';
import axios from "axios";
import {Sida,Rubrik,Stycke,ContentWrapper} from "./ContentStyles";
import Prices from "./Prices";
import CountDown from "./CountDown";


const shoot = () => {
  alert();
}

const Home = () => {

   return(
        <ContentWrapper>
    
        <Container>
        <Sida>
          
          <Rubrik>Används din utrustning?</Rubrik>
          <Stycke>
            Gymetric är systemet som hjälper dig att ta besluten som gör din anläggning attraktiv. Kunskap om vilken utrustning som används hjälper dig att göra rätt investeringar.
          </Stycke>


          <Rubrik>Abonnemang</Rubrik>
          <Prices />
          <Faq />

        </Sida>    
        </Container>

        </ContentWrapper>
      );
   }

export default Home;