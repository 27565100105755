import { Container, Button,Form, Alert} from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper} from "../ContentStyles";
import SpinnerX from '../SpinnerX';

import {Pageheight} from "../../index";

import React from 'react';

class MyComponent extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
          data: null,
          isLoading: false,
          error: null,
          resultVariant :"success",
          resultVisible:false,
          resultText :'N/A',
          spinnerHidden:false,
          InstanceGuid:null,
          invoiceType:1
        }
    }

    CheckAccessToken;

    handleSubmit = (e) =>{
        e.preventDefault();

        const formData = new FormData(e.target);
        const formDataObj = Object.fromEntries(formData.entries());

        this.setState({ resultVariant :'warning', resultVisible:false, resultText: '', spinnerHidden:false  });
        var PostData = {SystemID:"GM", MyData : formDataObj};          
        
        fetch('/backend/mydata', {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                'accessToken':localStorage.getItem('access_token'),
            },
            body: JSON.stringify(PostData),
            }).then(response => response.json())
            .then(data => {
                if (data.errorsException===false) {
                    this.setState({ resultVariant :'success', resultVisible:true, resultText: data.resultText, spinnerHidden:true  });
                } else {
                    this.setState({ resultVariant :'warning', resultVisible:true, resultText: data.resultText, spinnerHidden:true  });
                }
            })
            .catch(error => {
    
            this.setState({ resultVariant :'warning', resultVisible:true, resultText: 'Ett fel uppstod!', spinnerHidden:true  });
    
        });
    }  

    componentDidMount() {
        this.getData();
    }

    getData = () =>{
    //getData() {
      fetch('/backend/mydata', {
        method: 'GET',
        headers: {
           'Content-Type': 'application/json',
           'accessToken':localStorage.getItem('access_token'),
        },
     }).then(response => response.json())
     .then(data => {
        if (data.errorsException===false) {
            
            this.setState({ resultVisible:false, spinnerHidden:false  });

            document.getElementById("formDataContact").value = data.myData.contact;
            //document.getElementById("formDataName").value = data.myData.name;
            document.getElementById("formDataEmail").value = data.myData.email;
            document.getElementById("formDataCompany").value = data.myData.company;
            document.getElementById("formDataOrgnr").value = data.myData.orgnr;

            document.getElementById("formDataStreet01").value = data.myData.street01;
            document.getElementById("formDataZip").value = data.myData.zip;
            document.getElementById("formDataCity").value = data.myData.city;
            document.getElementById("formDataCountry").value = data.myData.country;
            document.getElementById("formDataInvoiceGLN").value = data.myData.invoiceGLN;
            document.getElementById("formDataInvoiceMail").value = data.myData.invoiceMail;

            this.setState({ resultVariant :'success', resultVisible:false, resultText: data.resultText, spinnerHidden:true, InstanceGuid:data.myData.guid, invoiceType: data.myData.invoiceType });
            
        } else {
            this.setState({ resultVariant :'warning', resultVisible:true, resultText: data.resultText, spinnerHidden:true  });

        }
     })
     .catch(error => {
        this.setState({ resultVariant :'warning', resultVisible:true, resultText: 'Ett fel uppstod!', spinnerHidden:true  });
        //this.setState({   });
      });
    }

    render() {
    return (
        <ContentWrapper>
            <Container>
                <Sida style={{minHeight:Pageheight(400)}}>
                <Rubrik>Mina Sidor</Rubrik>

                <Form onSubmit={this.handleSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Control type="text" name ="contact" placeholder="Kontaktperson"   id="formDataContact"/>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Control type="email" name="Email" placeholder="Email" id="formDataEmail" disabled/>
                    </Form.Group>

                    <Form.Group className="mb-3" >
                        <Form.Control type="text" name="company" placeholder="Företag"  id="formDataCompany"/>
                    </Form.Group>

                    <Form.Group className="mb-3" >
                        <Form.Control type="text" name="orgnr" placeholder="Orgnr"  id="formDataOrgnr" disabled/>
                    </Form.Group>    

                    <Form.Group className="mb-3" >
                        <Form.Control type="text" name="street01" placeholder="Gatuadress"  id="formDataStreet01"/>
                    </Form.Group>    

                    <Form.Group className="mb-3" >
                        <Form.Control type="text" name="zip" placeholder="Postnr" id="formDataZip" />
                    </Form.Group>   

                    <Form.Group className="mb-3" >
                        <Form.Control type="text" name="city" placeholder="Ort"  id="formDataCity"/>
                    </Form.Group>        
                    
                    <Form.Group className="mb-3" >
                        <Form.Control type="text" name="country" placeholder="Land"  id="formDataCountry"/>
                    </Form.Group>

                    <Form.Group className="mb-3" >
                        <Form.Control as="select"  name="invoiceType" value={this.state.invoiceType} onChange={e => {this.setState({invoiceType:e.target.value}); }}>
                            <option value={1}>Faktura via post 40:-/månad)</option>
                            <option value={2}>PDF-Faktura via E-post</option>
                            <option value={3}>E-Faktura</option>
                        </Form.Control>
                    </Form.Group>                             

                    <Form.Group className="mb-3" >
                        <Form.Control type="text" name="invoiceGLN" placeholder="E-faktura GLN"  id="formDataInvoiceGLN"/>
                    </Form.Group>     

                    <Form.Group className="mb-3" >
                        <Form.Control type="text" name="invoiceMail" placeholder="Epost Faktura"  id="formDataInvoiceMail"/>
                    </Form.Group>    

                    <Form.Group className="mb-3"  hidden={this.state.spinnerHidden}>
                        <SpinnerX  />
                    </Form.Group>                     

                    <Form.Group className="mb-3"  hidden={!this.state.resultVisible}>
                        <Alert show={this.state.resultVisible} variant={this.state.resultVariant}  className="FormSpace">{this.state.resultText}</Alert> 
                    </Form.Group>        

                    <Form.Group className="mb-3">
                        <Button className="QmeButton" variant="primary" type="submit">Spara</Button>
                    </Form.Group>    

                    <Form.Group className="mb-3">
                        <a className="text-decoration-none" style={{color:'#000', fontSize:'16px'}} href="/instance/terminate">Avsluta konto</a>&nbsp;&nbsp;&nbsp;&nbsp;
                        <a className="text-decoration-none" style={{color:'#000', fontSize:'16px'}} href="/password/change">Byt lösenord</a>&nbsp;&nbsp;&nbsp;&nbsp;
                  
                    </Form.Group>                                     
                </Form>      
            </Sida>
            </Container>
        </ContentWrapper>
    );
  }
}

export default MyComponent;