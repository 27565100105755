import { Container, Button,Form, Alert,Table} from 'react-bootstrap';
import SpinnerX from '../SpinnerX';
import {Pageheight} from "../../index";
import React, { useState, Component } from 'react';
import {Sida,Rubrik,ContentWrapper,StyckeCompact} from "../ContentStyles";
import useBackend from '../../services/useBackend';
import '../../App.css';
import {DateTimeToLocal} from '../../services/misc';

export default function DeviceInfo(props) {

  const {loading, data:Data} =  useBackend('/api/v1/Devices/'+ props.DeviceGUID );  

  if (!loading) {
    return (
      <StyckeCompact>
        <Table>
          <tbody>
            <tr><td>Namn</td><td><large><b>{Data.DeviceData.Name}</b></large></td></tr>
            <tr><td>Enhetstyp</td><td>{Data.DeviceData.DeviceType.Name}</td></tr>
            <tr><td>Skapad</td><td>{DateTimeToLocal(Data.DeviceData.Created)}</td></tr>
            <tr><td>Senaste aktivitet</td><td>{DateTimeToLocal(Data.DeviceData.LastActive)}</td></tr>
            <tr><td>Status</td><td>{Data.DeviceData.MeasurementId==0? 'Tillgänglig':'Mätning pågår'}</td></tr>
          </tbody>
        </Table>
      </StyckeCompact>  
    )
  } else {
    return (
        <ContentWrapper>
            <Container>
                <Sida style={{minHeight:Pageheight(400)}}>
                <Rubrik>Laddar info..</Rubrik>
                <SpinnerX  />
            </Sida>
            </Container>
        </ContentWrapper>
    );        
  }
}