
import { Container, Button,Form,Alert } from 'react-bootstrap';
import {Sida,Rubrik,Stycke,ContentWrapper} from "./ContentStyles";
import React, { useState,useEffect } from 'react';
import SpinnerX from './SpinnerX';

const Signup = () => {

    const params = new URLSearchParams(
        window ? window.location.search : {}
    );

    const searchParams = params.get('P');

    const [resultText, setResultText] = useState(":)");
    const [resultVariant, setResultVariant] = useState("success");
    const [resultVisible, setResultVisible] = useState(false);
    const [spinnerHidden, setSpinnerHidden] = useState(true);
    const [productValue, setproductValue] = useState(params.get('defaultproduct'));

    const [size, setSize] = useState({
        x: window.innerWidth,
        y: (window.innerHeight-500)
      });
    
      const updateSize = () => {
        setSize({
          x: window.innerWidth,
          y: (window.innerHeight-500)
        });

      }
      useEffect(() => (window.onresize = updateSize), []);  
      useEffect(() => (window.onscroll = updateSize), []);      
      
      useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    function handleSubmit(e) {
        e.preventDefault();

        setSpinnerHidden(false);
        setResultVisible(true);

        const formData = new FormData(e.target),
        formDataObj = Object.fromEntries(formData.entries())
    
        var PostData = {SystemID:"GM", FormData : formDataObj};  

        fetch('/backend/forms/signup', {
        method: 'POST',
        headers: {
           'Content-Type': 'application/json'
        },
        body: JSON.stringify(PostData),
     }).then(response => response.json())
     .then(data => {
        if (data.errorsException==false) {
            setResultVariant("success");      
            setResultText(data.resultText);        
            setSpinnerHidden(true);
            setResultVisible(true);
        } else {
            setResultVariant("warning");
            setResultText(data.resultText);
            setSpinnerHidden(true);
            setResultVisible(true);
        }
     })
     .catch(error => {
        setResultVariant("warning");
        //setResultText("Ett fel uppstod!" + JSON.stringify(PostData));
        setResultText("QmeNow öppnar för registrering 2022-03-01. Behöver du ett kösystem nu kan du kontakta oss via vårt kontaktformulär eller på info@cloudsolution.se");
        setSpinnerHidden(true);
        setResultVisible(true);
      });
    }

        

    
    return (
        <ContentWrapper>
            <Container>
            <Sida style={{minHeight:size.y}}>
                <Rubrik>Bli kund!</Rubrik>

                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="formBasicEmail1">
                        <Form.Control type="text" name ="contact" placeholder="Kontaktperson"  />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail3">
                        <Form.Control type="email" name="Email" placeholder="Email" />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail4">
                        <Form.Control type="text" name="company" placeholder="Företag" />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail5">
                        <Form.Control type="text" name="orgnr" placeholder="Orgnr" />
                    </Form.Group>    

                    <Form.Group className="mb-3" controlId="formBasicEmail6">
                        <Form.Control type="text" name="street01" placeholder="Gatuadress" />
                    </Form.Group>    

                    <Form.Group className="mb-3" controlId="formBasicEmail7">
                        <Form.Control type="text" name="zip" placeholder="Postnr" />
                    </Form.Group>   

                    <Form.Group className="mb-3" controlId="formBasicEmail8">
                        <Form.Control type="text" name="city" placeholder="Ort" />
                    </Form.Group>         

                    <Form.Group className="mb-3"  controlId="formBasicEmail11">
                        <Form.Control as="select"  name="product_id" value={productValue}           onChange={e => {console.log("e.target.value", e.target.value); setproductValue(e.target.value);} }>
                            <option value="GM10">Gymetric Basic 500:-/månad</option>
                            <option value="GM20">Gymetric  Standard 1000:-/månad</option>
                            <option value="GM30">Gymetric  Pro 1500:-/Månad</option>
                            <option value="GM40">Gymetric  Enterprice 2000:-/Månad</option>                                                    
                        </Form.Control>
                    </Form.Group> 

                    <Form.Group className="mb-3"  controlId="formBasicEmail22">
                        <Form.Control type="password" name="password" placeholder="Lösenord" />
                    </Form.Group>                                                                                                                                 

                    <Form.Group className="mb-3"  controlId="formBasicEmail33">
                        <Form.Control type="password" name="password_validate" placeholder="Lösenord" />
                    </Form.Group>                                 


                    <Stycke hidden={spinnerHidden}>
                            <SpinnerX  /><br></br>
                        </Stycke>                                

                        <Alert show={resultVisible} variant={resultVariant} Style="font-size: 20px;">{resultText}</Alert>

                    <Button className="QmeButton" variant="primary" type="submit">Submit</Button>
                </Form>      
            </Sida>    
            </Container>
        </ContentWrapper>
    );
};

export default Signup;