import '../App.css';
import {PricingTable, PricingSlot, PricingDetail} from 'react-pricing-table';
import React, { useState, Component } from 'react';
import {  useNavigate } from 'react-router';

const Prices = () => {
    let navigate = useNavigate();

    function handleSubmitProd1(e) {
        e.preventDefault();
        navigate('/signup?defaultproduct=GM10'); 
    }    

    function handleSubmitProd2(e) {
        e.preventDefault();
        navigate('/signup?defaultproduct=GM20'); 
    }    
    
    function handleSubmitProd3(e) {
        e.preventDefault();
        navigate('/signup?defaultproduct=GM30'); 
    }    
    
    function handleSubmitProd4(e) {
        e.preventDefault();
        navigate('/signup?defaultproduct=GM40'); 
    }        

    return (
    <>
        <PricingTable highlightColor='#DEBF1A'>

            <PricingSlot onClick={handleSubmitProd1}   buttonText='SIGN UP' title='BASIC' priceText='500:-/månad' >

                <PricingDetail> <b>Feature 1</b> 3</PricingDetail>
                <PricingDetail> <b>Feature 2</b> Ja</PricingDetail>   
                <PricingDetail> <b>Feature 3</b> Nej</PricingDetail>  
                <PricingDetail> <b>Feature 4</b> Nej</PricingDetail>            
                <PricingDetail> <b>Feature 5</b> Nej</PricingDetail>             
                <PricingDetail> <b>Support</b> Mail</PricingDetail>      
            </PricingSlot>

            <PricingSlot onClick={handleSubmitProd2} highlighted  buttonText='SIGN UP' title='STANDARD' priceText='1000:-/månad' >
                
            <PricingDetail> <b>Feature 1</b> 3</PricingDetail>
                <PricingDetail> <b>Feature 2</b> Ja</PricingDetail>   
                <PricingDetail> <b>Feature 3</b> Nej</PricingDetail>  
                <PricingDetail> <b>Feature 4</b> Nej</PricingDetail>            
                <PricingDetail> <b>Feature 5</b> Nej</PricingDetail>           
                <PricingDetail> <b>Support</b> Mail</PricingDetail>      
            </PricingSlot>            

            <PricingSlot onClick={handleSubmitProd3}   buttonText='SIGN UP' title='PRO' priceText='1500:-/månad'>

            <PricingDetail> <b>Feature 1</b> 3</PricingDetail>
                <PricingDetail> <b>Feature 2</b> Ja</PricingDetail>   
                <PricingDetail> <b>Feature 3</b> Nej</PricingDetail>  
                <PricingDetail> <b>Feature 4</b> Nej</PricingDetail>            
                <PricingDetail> <b>Feature 5</b> Nej</PricingDetail>      
                <PricingDetail> <b>Support</b> Mail</PricingDetail>      
            </PricingSlot>

            <PricingSlot onClick={handleSubmitProd4}   buttonText='SIGN UP' title='Enterprise' priceText='2000:-/månad'>

            <PricingDetail> <b>Feature 1</b> 3</PricingDetail>
                <PricingDetail> <b>Feature 2</b> Ja</PricingDetail>   
                <PricingDetail> <b>Feature 3</b> Nej</PricingDetail>  
                <PricingDetail> <b>Feature 4</b> Nej</PricingDetail>            
                <PricingDetail> <b>Feature 5</b> Nej</PricingDetail>      
                <PricingDetail> <b>Support</b> Mail</PricingDetail>      
            </PricingSlot>            

        </PricingTable>
    </>
  )
};

export default Prices;        