import { Container, Button,Form, Alert,Table} from 'react-bootstrap';

import {Sida,Rubrik,ContentWrapper, Stycke, StyckeCompact} from "../ContentStyles";
import SpinnerX from '../SpinnerX';

import {Pageheight} from "../../index";
import useBackend from '../../services/useBackend';
import {FaBars, FaBatteryThreeQuarters} from 'react-icons/fa';
import { generatePath, useNavigate } from 'react-router';
import {DateTimeToLocal} from '../../services/misc';

import React from 'react';

export default function MyComponent() {
    const {loading, data} =  useBackend('/api/v1/Devices');

    const navigate = useNavigate();

    const operateLockClick = (event)  =>  {
        const DeviceGUID = event.currentTarget.getAttribute("DeviceGUID");
        navigate("/Device/"+DeviceGUID);
    }

    const claimDeviceHandler = (event)  =>  {
        event.preventDefault();
        navigate("/claimDevice");
    }        

    if (!loading) {
        return (
            <ContentWrapper>
                <Container>
                    <Sida style={{minHeight:Pageheight(400)}}>
                    <Rubrik>Mätenheter</Rubrik>
                    <StyckeCompact>
                        <Table  responsive="sm">
                            <thead>
                                <tr>    <td>Namn</td> <td>Typ</td> <td>Note</td> <td>Status</td> <td>Senast aktiv</td>  </tr>
                            </thead>
                            <tbody>
                                {data.Devices.sort((a, b) => a.Name.localeCompare(b.Name)).map((item) => {
                                    return <tr> 
                                        <td>{item.Name}</td>    
                                        <td>{item.DeviceType.Name}</td>  
                                        <td>{item.Note}</td>                                    
                                        <td>{item.MeasurementId==0? 'Tillgänglig':'Mätning pågår'}</td>
                                        <td>{item.LastActive !=='0001-01-01T00:00:00'?  DateTimeToLocal(item.LastActive):""}</td>
                                        <td >
                                            <span style={{padding:5}} className='sub_' DeviceGUID={item.Guid} onClick={operateLockClick} title="Info "><FaBars /></span>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>
                    </StyckeCompact>  

                    <Form>
                        <Form.Group className="mb-3">
                            <Button className="QmeButton" variant="primary" type="submit" onClick={claimDeviceHandler}>Anslut ny mätenhet</Button>
                        </Form.Group>                                
                    </Form>                 
                </Sida>
                </Container>
            </ContentWrapper>
        );
    } else {
        return (
            <ContentWrapper>
                <Container>
                    <Sida style={{minHeight:Pageheight(400)}}>
                    <Rubrik>Laddar mätenheter</Rubrik>
                    <SpinnerX  />
                </Sida>
                </Container>
            </ContentWrapper>
        );        
    }
}