import { Container, Button,Form, Alert,Table} from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper, Stycke, StyckeCompact} from "../ContentStyles";
import {Pageheight} from "../../index";
import SpinnerX from '../SpinnerX';
import {useParams, useNavigate} from 'react-router-dom';
import DeviceInfo from './DeviceInfo'
import React, { useState } from 'react';
import BackendFunction from '../../services/BackendFunction';

export default function NewMeasurement(props) {
    const {DeviceGUID} = useParams();

    const navigate = useNavigate();

    const [loading, setLoadning] = useState(false);
    const [resultVariant, setResultVariant] = useState('warning');
    const [resultText, setResultText] = useState('N/A');
    const [resultVisible, setResultVisible] = useState(false);

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );      

    const today = new Date();
    // const numberOfDaysToAdd = 3;
    const date = today.setDate(today.getDate()); 
    const datePlusWeek = today.setDate(today.getDate() +7); 
    const defaultTimeFrameStart = new Date(date).toISOString().substring(0,16); // yyyy-mm-dd
    const defaultTimeFrameEnd = new Date(datePlusWeek).toISOString().substring(0,16); //.split('T')[0] // yyyy-mm-dd    

     
    const onInitClick = async (e) =>{
        e.preventDefault();

        setLoadning(true);

        setResultText("Skapar mätning...");
        setResultVariant("warning");
        setResultVisible(true);

        var MeasurementData = {  };
        MeasurementData.DeviceGUID = DeviceGUID;
        MeasurementData.Name = e.target.elements.Name.value;
        MeasurementData.Description = e.target.elements.Description.value;
        MeasurementData.TimeFrameStart = e.target.elements.TimeFrameStart.value;
        MeasurementData.TimeFrameEnd = e.target.elements.TimeFrameEnd.value;

        var {resultVariant, resultVisible, resultText} = await BackendFunction('/api/v1/Measurement/Init','POST',MeasurementData);

        await delay(1000);

        console.log(MeasurementData);
        setResultVariant(resultVariant);
        setResultText(resultText);
        setLoadning(false);        
    }  

    return (
        <ContentWrapper>
            <Container>
                <Sida style={{minHeight:Pageheight(400)}}>
                <Rubrik>Ny mätning</Rubrik>
                <DeviceInfo DeviceGUID={DeviceGUID} />

                <Form onSubmit={onInitClick}>

                    <Form.Group className="mb-3">
                        <Form.Label>Namn</Form.Label>
                        <Form.Control type="text" name ="Name" placeholder="Ange namn på mätningen"/>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Anmärkning</Form.Label>
                        <Form.Control type="text" name ="Description" placeholder="Beskrivning"/>
                    </Form.Group>

                    <Form.Group className="mb-3" >
                        <Form.Label>Starttid</Form.Label>
                        <Form.Control type="datetime-local" name="TimeFrameStart" defaultValue={defaultTimeFrameStart}/>
                    </Form.Group>                    

                    <Form.Group className="mb-3" >
                        <Form.Label>Sluttid</Form.Label>
                        <Form.Control type="datetime-local" name="TimeFrameEnd"  defaultValue={defaultTimeFrameEnd}/>
                    </Form.Group>                                        
                      
                    <Form.Group className="mb-3"  hidden={!loading}>
                        <SpinnerX  />
                    </Form.Group>                                      
                        
                    <Form.Group className="mb-3"  hidden={!resultVisible}>
                        <Alert show={resultVisible} variant={resultVariant}  className="FormSpace">{resultText}</Alert> 
                    </Form.Group>  

                    <Form.Group className="mb-3">
                        <Button  style={{margin:5}} className="QmeButton" variant="primary" type="submit">Starta ny mätning</Button>
                    </Form.Group>                                
                </Form>                 
            </Sida>
            </Container>
        </ContentWrapper>
    )
}