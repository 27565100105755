import ClipLoader from "react-spinners/PropagateLoader";
import React, { useState, Component } from 'react';

const SpinnerX = () => {


  let [color, setColor] = useState("#DEBF1A");
  
    return (
    <><br></br>
    <ClipLoader color={color}  size={30} />
    </>
    )
  };
  
  export default SpinnerX;