import React, { useState ,useEffect} from 'react';

export default function useBackend(url) {
    const [data, setData] = useState([]);
    const [loading, setLoadning] = useState(true);

    const [resultVariant, setResultVariant] = useState(null);
    const [resultText, setResultText] = useState(null);
    const [resultVisible, setResultVisible] = useState(null);

    useEffect(() => {

        async function init() {
            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'accessToken':localStorage.getItem('access_token'),
                    },
                });

                if (response.ok) {
                    const json = await response.json();
                    setResultVariant('success');
                    setResultVisible(false);
                    setResultText('');
                    setData(json);
                } else {
                    setResultVariant('warning');
                    setResultVisible(true);
                    setResultText('Ett fel uppstod!!');

                    throw response;
                }
            }
            catch (e) {
                setResultVariant('warning');
                setResultVisible(true);
                setResultText('Ett fel uppstod!!!');

            }
            finally {
                setLoadning(false);
            }
        }
        init();

    }, [url]);  

    return { resultVariant, resultVisible, resultText, loading, data }; 
}