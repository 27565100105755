import { Container, Button,Form, Alert,Table} from 'react-bootstrap';

import {Sida,Rubrik,ContentWrapper, Stycke, StyckeCompact} from "../ContentStyles";
import SpinnerX from '../SpinnerX';

import {Pageheight} from "../../index";
import useBackend from '../../services/useBackend';
import {FaBars, FaBatteryThreeQuarters} from 'react-icons/fa';
import { generatePath, useNavigate } from 'react-router';
import {DateTimeToLocal} from '../../services/misc';

import React from 'react';

export default function MyComponent() {
    const {loading, data} =  useBackend('/api/v1/Log');

    const navigate = useNavigate();    

    if (!loading) {
        return (
            <ContentWrapper>
                <Container>
                    <Sida style={{minHeight:Pageheight(400)}}>
                    <Rubrik>Log</Rubrik>
                    <StyckeCompact>
                        <Table  responsive="sm">
                            <thead>
                                <tr>    <td>Tid</td>  <td>Logtext</td> </tr>
                            </thead>
                            <tbody>
                                {data.LogData.map((item) => {
                                    return <tr>                                         
                                        <td>{item.MeasurementId!=0? DateTimeToLocal(item.Created):'' }</td>
                                        <td>{item.Logtext}</td>                                        
                                    </tr>
                                })}
                            </tbody>
                        </Table>
                    </StyckeCompact>             
                </Sida>
                </Container>
            </ContentWrapper>
        );
    } else {
        return (
            <ContentWrapper>
                <Container>
                    <Sida style={{minHeight:Pageheight(400)}}>
                    <Rubrik>Laddar log</Rubrik>
                    <SpinnerX  />
                </Sida>
                </Container>
            </ContentWrapper>
        );        
    }
}