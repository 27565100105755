import { Container, Button,Form, Alert,Table} from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper, Stycke, StyckeCompact} from "../ContentStyles";
import SpinnerX from '../SpinnerX';
import BackendFunction from '../../services/BackendFunction';
import {Pageheight, CheckAccessToken, XMenu, GetAccessToken} from "../../index";
import useBackend from '../../services/useBackend';
import {FaBars, FaChartLine, FaTrash} from 'react-icons/fa';
import { generatePath, useNavigate } from 'react-router';
import {DateTimeToLocal} from '../../services/misc';


import React from 'react';

export default function Measurements() {
 

    const {loading, data} =  useBackend('/api/v1/Measurement');

    const navigate = useNavigate();



    const handleDeleteClick = async (event) =>{
        const MeasurementGUID = event.currentTarget.getAttribute("MeasurementGUID");
        if (window.confirm('Vill du ta bort mätningen?')) {
            var {resultVariant, resultVisible, resultText} = await BackendFunction('/api/v1/Measurement/'+MeasurementGUID,'DELETE');

            window.location.reload();
    }
    }

    const handleLinkClick = (event)  =>  {
        const link = event.currentTarget.getAttribute("link");
        const MeasurementGUID = event.currentTarget.getAttribute("MeasurementGUID");
        navigate("/Measurement/"+MeasurementGUID+"/"+link);
    }   

    if (!loading) {
        return (
            <ContentWrapper>
                <Container>
                    <Sida style={{minHeight:Pageheight(400)}}>
                    <Rubrik>Mätningar</Rubrik>
                    <StyckeCompact>
                        <Table  responsive="sm">
                            <thead>
                                <tr>    <td>Namn</td> <td>WorkoutPerDayAvg</td> <td>WorkoutsTotal</td> <td>Mätperiod</td> <td>Status</td>  </tr>
                            </thead>
                            <tbody>
                                {data.Measurements.map((item) => {
                                    return <tr> <td>{item.Name}</td>   <td>{item.WorkoutPerDayAvg}</td> <td>{item.WorkoutsTotal}</td> <td>{DateTimeToLocal(item.StartTime)} - {DateTimeToLocal(item.EndTime)}</td> <td>{item.State.Name}</td>
                                        <td >

                                            <span style={{padding:5}} className='sub_' MeasurementGUID={item.Guid} Link='Statistics' onClick={handleLinkClick} title="Statistik "><FaChartLine /></span>
                                            <span style={{padding:5}} className='sub_' MeasurementGUID={item.Guid} Link='Statistics' onClick={handleDeleteClick} title="Radera "><FaTrash /></span>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>
                    </StyckeCompact>                
                </Sida>
                </Container>
            </ContentWrapper>
        );
    } else {
        return (
            <ContentWrapper>
                <Container>
                    <Sida style={{minHeight:Pageheight(400)}}>
                    <Rubrik>Laddar mätningar...</Rubrik>
                    <SpinnerX  />
                </Sida>
                </Container>
            </ContentWrapper>
        );        
    }
}