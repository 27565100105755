import { Container, Button,Form, Alert,Table} from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper,StyckeCompact} from "../ContentStyles";
import SpinnerX from '../SpinnerX';
import {Pageheight} from "../../index";
import React, { useState ,useEffect} from 'react';
import useBackend from '../../services/useBackend';
import BackendFunction from '../../services/BackendFunction';
import {useParams, useNavigate} from 'react-router-dom';
import {DateTimeToLocal, getOptions} from '../../services/misc';

export default function DeviceSettings() {

    // const [loading, setLoadning] = useState(false);
    const [resultVariant, setResultVariant] = useState('warning');
    const [resultText, setResultText] = useState('N/A');
    const [resultVisible, setResultVisible] = useState(false);    
    const [loading, setLoadning] = useState(false);
    const [buttonsDisabled, setButtonsDisabled] = useState(false);

    const navigate = useNavigate();   

    const {DeviceGUID} = useParams();    

    const {loading:metaLoading, data:metaData} =  useBackend(process.env.PUBLIC_URL+'/api/v1/meta');
    const {loading:loadinit, data:Data} =  useBackend('/api/v1/Devices/'+ DeviceGUID + '' );
    

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );  

    const handleSaveClick = async (e) =>{
        e.preventDefault();
        setLoadning(true);

        setResultText("Sparar inställningar.....");
        setResultVariant("warning");
        setResultVisible(true);
                
        var data = { DeviceGUID : DeviceGUID };
        data.DeviceName = e.target.elements.DeviceName.value;
        data.DeviceNote = e.target.elements.DeviceNote.value;
        data.DeviceTypeId = parseInt(e.target.elements.DeviceTypeId.value);
        data.TurnOverTime = parseInt(e.target.elements.TurnOverTime.value);

        console.log(data);

        const {resultVariant, resultVisible, resultText} = await  BackendFunction('/api/v1/Devices/'+ DeviceGUID,'PATCH', data );  
        
        await delay(1000);

        setResultVariant(resultVariant);   
        setResultText(resultText);
        setResultVisible(true);

        setLoadning(false);        
    }     
    
    const handleDeleteClick = async (e) =>{
        e.preventDefault();

        if (window.confirm('Vill du ta bort mätenheten?')) {
                var {resultVariant, resultVisible, resultText} = await BackendFunction('/api/v1/Devices/'+DeviceGUID,'DELETE');
                setResultVariant(resultVariant);   
                setResultText(resultText);
                setResultVisible(resultVisible);
                //window.location.reload();
                setButtonsDisabled(true);
        }
    }        

    const handleLinkClick = (event)  =>  {
        const link = event.currentTarget.getAttribute("link");
        navigate("/Locks/"+DeviceGUID+"/"+link);
    }    

    if (!loadinit && !metaLoading) {
        return (
            <ContentWrapper>
                <Container>
                    <Sida style={{minHeight:Pageheight(400)}}>
                    <Rubrik>Inställningar</Rubrik>

                    <Form onSubmit={handleSaveClick}>
                        <Form.Group className="mb-3">
                            <Form.Label>Namn</Form.Label>
                            <Form.Control type="text" name ="DeviceName" placeholder="Ange namn på mätenheten" defaultValue={Data.DeviceData.Name}/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Note</Form.Label>
                            <Form.Control type="text" name ="DeviceNote" placeholder="" defaultValue={Data.DeviceData.Note}/>
                        </Form.Group>                        

                        <Form.Group className="mb-3">
                            <Form.Label>Typ</Form.Label>
                            <Form.Select name="DeviceTypeId" defaultValue={Data.DeviceData.DeviceType.Id}  >                
                                {getOptions(metaData.DeviceTypes)}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Minsta tid (sekunder) innan nästa händelse</Form.Label>
                            <Form.Control type="text" name ="TurnOverTime" placeholder="Anges i sekunder" defaultValue={Data.DeviceData.TurnOverTime}/>
                        </Form.Group>                                                
                      
                        <Form.Group className="mb-3"  hidden={!loading}>
                            <SpinnerX  />
                        </Form.Group>                                      
                            
                        <Form.Group className="mb-3"  hidden={!resultVisible}>
                            <Alert show={resultVisible} variant={resultVariant}  className="FormSpace">{resultText}</Alert> 
                        </Form.Group>    

                        <Form.Group className="mb-3">
                            <Button  style={{margin:5}} className="QmeButton" variant="primary" type="submit" disabled={buttonsDisabled}>Spara</Button>
                            <Button  style={{margin:5}} className="QmeButton" variant="primary" type="submit" disabled={buttonsDisabled} onClick={handleDeleteClick} link="DeleteLock">Ta bort mätenhet</Button>
                        </Form.Group>                                                                              
                    </Form>                 
                </Sida>
                </Container>
            </ContentWrapper>
        );
    } else {
        return (
            <ContentWrapper>
                <Container>
                    <Sida style={{minHeight:Pageheight(400)}}>
                    <Rubrik>Laddar inställningar</Rubrik>
                    <SpinnerX  />
                </Sida>
                </Container>
            </ContentWrapper>
        );        
    }
}