import { Container, Button,Form, Alert,Table} from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper, Stycke, StyckeCompact} from "../ContentStyles";
import {Pageheight, CheckAccessToken, XMenu, GetAccessToken} from "../../index";
import {useParams, useNavigate} from 'react-router-dom';
import DeviceInfo from './DeviceInfo'
import React from 'react';

export default function DeviceHistory(props) {
    const {DeviceGUID} = useParams();

    const navigate = useNavigate(); 

    return (
        <ContentWrapper>
            <Container>
                <Sida style={{minHeight:Pageheight(400)}}>
                <Rubrik>Historik</Rubrik>
                <DeviceInfo DeviceGUID={DeviceGUID} /> 

                <StyckeCompact>
Ej klar..
                </StyckeCompact>              
            </Sida>
            </Container>
        </ContentWrapper>
    )
}