import CookieConsent from "react-cookie-consent";

const CookieConsentX = () => {
    return (
        <CookieConsent
            location="bottom"
            buttonText="Jag förstår :)"
            cookieName="CookieConcent_QuemeNow_1"
            style={{ background: "#2B373B", opacity: 0.9 }}
            buttonStyle={{ background:"#DEBF1A", color: "#fff", fontSize: "16px", fontWeight_: "bold" }}
            //declineButtonText="Mer info"
            //enableDeclineButton="true"            
            //declineButtonStyle={{ background:"#ffaec9", color: "#fff", fontSize: "16px" }}
            expires={1}

            onAccept={(acceptedByScrolling) => {
                // if (acceptedByScrolling) {
                //     // triggered if user scrolls past threshold
                //     alert("Accept was triggered by user scrolling");
                // } else {
                //     alert("Accept was triggered by clicking the Accept button");
                // }
            }}
        >
        Vi använder cookies för att ge dig bästa möjliga kundupplevelse. Om du fortsätter innebär det att du accepterar att cookies används.
        När du besöker våra webbplatser och appar samlar vi in uppgifter från dig för att förbättra din användarupplevelse.

        </CookieConsent>
    );
};

export default CookieConsentX;