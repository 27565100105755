import { Container, Button,Form, Alert,Table} from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper, Stycke, StyckeCompact} from "../ContentStyles";
import {Pageheight} from "../../index";
import SpinnerX from '../SpinnerX';
import {useParams, useNavigate} from 'react-router-dom';
import DeviceInfo from './DeviceInfo'
import React, { useState } from 'react';
import useBackend from '../../services/useBackend';
import BackendFunction from '../../services/BackendFunction';

export default function MyComponent(props) {
    const {DeviceGUID} = useParams();

    const navigate = useNavigate();

    //const [loading, setLoadning] = useState(false);
    const [resultVariant, setResultVariant] = useState('warning');
    const [resultText, setResultText] = useState('N/A');
    const [resultVisible, setResultVisible] = useState(false);
    const [onGoingMeasurement, setonGoingMeasurement] = useState(false);

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );      
 
    const StopLinkClick = async (e) =>{
        e.preventDefault();

        //setLoadning(true);

        setResultText("Soppar mätning...");
        setResultVariant("warning");
        setResultVisible(true);

        var {resultVariant, resultVisible, resultText} = await BackendFunction('/api/v1/Measurement/'+DeviceGUID+'/End','PUT');
        await delay(1000);

        setResultText(resultText);
        setResultVariant(resultVariant);
        
        await delay(1000);
        window.location.reload();

        //setLoadning(false);        
    }  
    
    const handleLinkClick = (event)  =>  {
        const link = event.currentTarget.getAttribute("link");
        navigate("/Devices/"+DeviceGUID+"/"+link);
    }    

    const {loading, data:Data} =  useBackend('/api/v1/Devices/'+ DeviceGUID );  

    if (!loading) {
        
        return (
            <ContentWrapper>
                <Container>
                    <Sida style={{minHeight:Pageheight(400)}}>
                    <Rubrik>Detaljer</Rubrik>
                    <DeviceInfo DeviceGUID={DeviceGUID} />

                    <Form>
                        <Form.Group className="mb-3"  hidden={!loading}>
                            <SpinnerX  />
                        </Form.Group>                                      
                            
                        <Form.Group className="mb-3"  hidden={!resultVisible}>
                            <Alert show={resultVisible} variant={resultVariant}  className="FormSpace">{resultText}</Alert> 
                        </Form.Group>  

                        <Form.Group className="mb-3">
                                <Button  style={{margin:5}} className="QmeButton" variant="primary" type="submit" onClick={handleLinkClick} link="NewMeasurement" disabled={Data.DeviceData.MeasurementId==0? false:true }>Starta ny mätning</Button>
                                <Button  style={{margin:5}} className="QmeButton" variant="primary" type="submit" onClick={StopLinkClick} disabled={Data.DeviceData.MeasurementId==0? true:false } >Avsluta mätning</Button>
                                <Button  style={{margin:5}} className="QmeButton" variant="primary" type="submit" onClick={handleLinkClick} link="Settings">Inställningar</Button>
                        </Form.Group>                                
                    </Form>                 
                </Sida>
                </Container>
            </ContentWrapper>
        )
    } else {
        return (
            <ContentWrapper>
                <Container>
                    <Sida style={{minHeight:Pageheight(400)}}>
                    <Rubrik>Laddar info..</Rubrik>
                    <SpinnerX  />
                </Sida>
                </Container>
            </ContentWrapper>
        );        
    }
}