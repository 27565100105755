import { Container, Button,Form, Alert,Table} from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper, Stycke, StyckeCompact} from "../ContentStyles";
import {Pageheight} from "../../index";
import SpinnerX from '../SpinnerX';
import {useParams, useNavigate, Link} from 'react-router-dom';
import React, { useState } from 'react';
import useBackend from '../../services/useBackend';
import { useSearchParams } from 'react-router-dom'

import ReactApexChart from 'react-apexcharts'

export default function MyComponent(props) {
    const {MeasurementGUID} = useParams();

    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    const z = searchParams.get('z')
    
    const options = {

        chart: {
          type: 'bar',
          foreColor: '#373d3f'  // Text
        },

        colors: ['#DEBF1A', '#66DA26', '#DEBF1A', '#E91E63', '#FF9800'],

        plotOptions: {
            bar: {
                borderRadius: 10,
                distributed_: true
            }
        },        
   
        xaxis: {
          type_: 'datetime',
          // categories: [1, 2, 3, 4], //will be displayed on the x-asis
          position: 'bottom',

          tooltip: {
            enabled: true,
          }          
        }
    };  

    // const [loading, setLoadning] = useState(false);
    const [resultVariant, setResultVariant] = useState('warning');
    const [resultText, setResultText] = useState('N/A');
    const [resultVisible, setResultVisible] = useState(false);
    const [onGoingMeasurement, setonGoingMeasurement] = useState(false);

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );      
    
    const handleLinkClick = (event)  =>  {
        event.preventDefault();
        const link = event.currentTarget.getAttribute("link");
        navigate("/Measurement/"+MeasurementGUID+"/"+link);
    }    

    const {loading, data:Data} =  useBackend('/api/v1/Measurement/'+ MeasurementGUID + '/Diagram?z='+z );  
    
    if (!loading) {

        var series = [
            {
              name: Data.ReportData.Name + ' ' +Data.ReportData.Description, //will be displayed on the y-axis
              data: Data.ReportData.Activity
            }     
        ];

        return (
            <ContentWrapper>
                <Container>
                    <Sida style={{minHeight:Pageheight(400)}}>
                        <Rubrik>Statistik</Rubrik>
                        <StyckeCompact>
                            <Table>
                            <tbody>
                                <tr><td>Namn</td><td><large><b>{Data.ReportData.Name}</b></large></td></tr>
                                <tr><td>Beskrivning</td><td>{Data.ReportData.Description}</td></tr>
                                <tr><td>Status</td><td>{Data.ReportData.State.Name}</td></tr>

                            </tbody>
                            </Table>
                        </StyckeCompact>  

                        <ReactApexChart options={options} series={series} type="bar" height={350} />             

                        <Form>
                            <Form.Group className="mb-3"  hidden={!loading}>
                                <SpinnerX  />
                            </Form.Group>                                      
                                
                            <Form.Group className="mb-3"  hidden={!resultVisible}>
                                <Alert show={resultVisible} variant={resultVariant}  className="FormSpace">{resultText}</Alert> 
                            </Form.Group>  

                            <Form.Group className="mb-3">
                                    <Button  style={{margin:5}} className="QmeButton" variant="primary" type="submit" onClick={handleLinkClick} link="Statistics?z=d">Visa/Dag</Button>
                                    <Button  style={{margin:5}} className="QmeButton" variant="primary" type="submit" onClick={handleLinkClick} link="Statistics?z=h">Visa/Timme</Button>
                            </Form.Group>                                
                        </Form>   
                    </Sida>
                </Container>
            </ContentWrapper>
        )
    } else {
        return (
            <ContentWrapper>
                <Container>
                    <Sida style={{minHeight:Pageheight(400)}}>
                    <Rubrik>Laddar info..</Rubrik>
                    <SpinnerX  />
                </Sida>
                </Container>
            </ContentWrapper>
        );        
    }
}