import styled from 'styled-components';

export const Box = styled.div`
	padding: 20px ;
	background: #DEBF1A;
	opacity:1.0;
	width: 100%;

	@media (max-width: 1000px) {
		padding: 70px 30px;
	}
	z-index: 99;
`;

export const Container = styled.div`
	//display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 1200px;
	margin: 0 auto;
`;

export const Column = styled.div`
	display: flex;
	flex-direction: column;
	text-align: left;
	margin-left: 60px;
`;

export const Row = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill,
							minmax(285px, 1fr));
	grid-gap: 20px;

	@media (max-width: 1000px) {
		grid-template-columns: repeat(auto-fill,
							minmax(200px, 1fr));
	}
`;

export const FooterLink = styled.a`
	color: #fff;
	margin-bottom: 20px;
	font-size: 18px;
	text-decoration: none;

	&:hover {
		color: green;
		transition: 200ms ease-in;
	}
`;

export const SocialMediaBlock = styled.div`
	padding-right: 20px;
	float:left;
`;

export const Heading = styled.p`
	font-size: 24px;
	color: #fff;
	margin-bottom: 20px;
	font-weight: bold;
`;

export const Address = styled.p`
	font-size: 16px;
	color: #fff;
	margin-bottom: 40px;
`;